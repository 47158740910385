import React from 'react';
import { Button } from '@hagerty/react-components';

import styles from './RoadsideHelpBar.module.scss';

import useRoadsideAssets from 'src/components/Roadside/useRoadsideAssets';
import FadeIn from 'src/components/FadeIn';
import { scrollToElementById } from 'src/helpers/scrollToElementById';
import { Split } from 'src/constants/Split';
import { useFreeTrial } from 'src/hooks/useFreeTrial';
import { useTrackingContext } from 'src/components/TrackingContext';
import { formatTestExperience } from 'src/helpers/formatTestExperience';

export const RoadsideHelpBar: React.FC = () => {
  const assets = useRoadsideAssets();
  const { help } = assets;
  const { trackInteraction, trackAction } = useTrackingContext();
  const { isEligibleForFreeTrial } = useFreeTrial();

  const freeTrialExperiment = formatTestExperience(Split.FeatureFreeTrial, isEligibleForFreeTrial);
  const customProperties = {
    test_experience: [freeTrialExperiment],
  };

  const handleDownloadClick = () => {
    trackInteraction('click', help.downloadLink.text, customProperties);
    scrollToElementById(help.downloadLink.href);
  };

  const handleCallClick = () => {
    trackAction('button', 'Roadside Page Get Help Now');
  };

  return (
    <FadeIn duration={0.3}>
      <div className={styles.roadsideHelpBar} data-cy="roadside-help" id="roadside-help">
        <div className={styles.roadsideHelpBar__leftContainer}>
          <img className={`${styles.roadsideHelpBar__icon} icon`} src={help.svg} alt="icon" />
          <p className={styles.roadsideHelpBar__title} data-cy="roadside-help-title">
            {help.title}
          </p>
          <p className={styles.roadsideHelpBar__subtitle} data-cy="roadside-help-subtitle">
            {help.subtitle}
          </p>
        </div>
        <div className={styles.roadsideHelpBar__linkContainer}>
          <a
            href="https://hagerty.rsahelp.com"
            target="_blank"
            className={`${styles.roadsideHelpBar__link} button button_primary`}
            data-cy="roadside-help-cta"
            onClick={handleCallClick}
          >
            Get Help Now
          </a>
          <Button
            className={`${styles.roadsideHelpBar__link} button button__tertiary`}
            onClick={handleDownloadClick}
            testId="roadside-help-download"
          >
            {help.downloadLink.text}
          </Button>
        </div>
      </div>
    </FadeIn>
  );
};
